import axios from 'axios'
import VueRouter from 'vue-router'
import { getItem, removeItem } from '@/utils/localStorage'
const router = new VueRouter()
const request = axios.create({
  // 接口的基准路径
  baseURL: 'http://employee.zfbhelper.com'
})
// 请求拦截器
request.interceptors.request.use(function(config) {
  // 设置请求头
  config.headers = {
    token: getItem('token') || null
  }
  return config
})
// 响应拦截器
request.interceptors.response.use(
  function(config) {
    if (config.data.code === 100) {
      removeItem('token')
      router.push('/Login')
      location.reload()
    }
    return config.data
  },
  err => {
    console.log(err)
  }
)
export default request
